<template>
  <div>
    <!--begin::Head-->
    <div class="kt-login__head">
      <span class="kt-login__signup-label">Already have an account?</span>&nbsp;&nbsp;
      <router-link class="kt-link kt-login__signup-link" :to="{ name: 'login' }">Login!</router-link>
    </div>

    

    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>Confirmation Successful</h3>
        </div>
      </div>
    </div>

<div class="row justify-content-md-center">
    <b-alert show variant="light" class="alert alert-elevate col-md-7" >
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-brand"></i>
      </div>
      <div class="alert-text">
         <p>Confirmation email has been sent to <strong style="font-weight:bold !importnat">{{ eid }}</strong>. Please verify your account to proceed</p>
      </div>
    </b-alert>
</div>
    
    
    <!--end::Body-->
  </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>



<script>

export default {
  name: "confirmation",
  data() {
    return {
      eid: ''
    };
  },
  created() {
    this.eid = this.$route.params.eid;
  },
  backgroundImage() {
    return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
  }
};
</script>
